import React, { useState, useEffect, ChangeEvent } from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import { Data } from "../interfaces/interfaces";
import { useSearchFunction } from "../hooks/useSearchFunctions";

interface SearchFunctionProps {
  data: Data[];
  onFilteredDataChange: (filteredData: Data[]) => void;
}

export const SearchFunction: React.FC<SearchFunctionProps> = ({
  data,
  onFilteredDataChange,
}) => {
  const [query, setQuery] = useState<string>("");
  const [searchFields, setSearchFields] = useState({
    name: true,
    user: true,
    army: true,
    comments: true,
  });

  const filteredItems = useSearchFunction(data, query, searchFields);

  useEffect(() => {
    onFilteredDataChange(filteredItems);
  }, [filteredItems, onFilteredDataChange]);

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchFields({
      ...searchFields,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <div>
      <TextField
        label="Search"
        variant="outlined"
        value={query}
        onChange={handleSearch}
        fullWidth
        margin="normal"
      />
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              checked={searchFields.name}
              onChange={handleFieldChange}
              name="name"
            />
          }
          label="Name"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={searchFields.user}
              onChange={handleFieldChange}
              name="user"
            />
          }
          label="User"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={searchFields.army}
              onChange={handleFieldChange}
              name="army"
            />
          }
          label="Army"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={searchFields.comments}
              onChange={handleFieldChange}
              name="comments"
            />
          }
          label="Comments"
        />
      </FormGroup>
    </div>
  );
};
