import {
  User,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  sendEmailVerification,
  signOut,
} from "firebase/auth";
import { FC, ReactNode, useEffect, useState } from "react";
import { firebaseAuth } from "../firebase/firebase-config";
import UserContext, { UserContextType } from "../contexts/userProviderContext";

export const UserProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [buttonPressed, setButtonPressed] = useState<boolean>(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
      setUser(user || undefined);
      setIsLoading(false);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (buttonPressed) setButtonPressed(false);
  }, [buttonPressed]);

  const signup = async (
    email: string,
    password: string
  ): Promise<{ user?: User; error?: string }> => {
    setButtonPressed(true);
    try {
      const userCredential = await createUserWithEmailAndPassword(
        firebaseAuth,
        email,
        password
      );
      const user = userCredential.user;
      if (user) {
        await sendEmailVerification(user);
        setUser(user);
      }
      return { user };
    } catch (error) {
      console.error(error);
      return { error: (error as { message: string; code: string }).message };
    }
  };

  const signin = async (
    email: string,
    password: string
  ): Promise<{ user?: User; error?: string }> => {
    setButtonPressed(true);
    try {
      const userCredential = await signInWithEmailAndPassword(
        firebaseAuth,
        email,
        password
      );
      const user = userCredential.user;

      if (user && !user.emailVerified) {
        await signOut(firebaseAuth);
        return {
          error:
            "Email not verified. Please verify your email before signing in.",
        };
      }

      setUser(user);
      return { user };
    } catch (error) {
      console.error(error);
      return { error: (error as { message: string; code: string }).message };
    }
  };

  const logout = async () => {
    await firebaseAuth.signOut();
    setUser(undefined);
  };

  

  const value: UserContextType = {
    user,
    isLoading,
    signup,
    signin,
    logout,
    buttonPressed,
    setButtonPressed,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
