import { useState, useEffect } from "react";
import { Data } from "../interfaces/interfaces";

export const useSearchFunction = (
  data: Data[],
  search: string,
  searchButtonInput: {
    name: boolean;
    user: boolean;
    army: boolean;
    comments: boolean;
  }
): Data[] => {
  const [filteredItems, setFilteredItems] = useState<Data[]>(data);

  useEffect(() => {
    if (search === "") {
      setFilteredItems(data);
    } else {
      setFilteredItems(
        data.filter(
          (item) =>
            (searchButtonInput.name &&
              item.name?.toLowerCase().includes(search.toLowerCase())) ||
            (searchButtonInput.user &&
              item.userName?.toLowerCase().includes(search.toLowerCase())) ||
            (searchButtonInput.army &&
              item.army?.toLowerCase().includes(search.toLowerCase())) ||
            (searchButtonInput.comments &&
              item.comments?.toLowerCase().includes(search.toLowerCase()))
        )
      );
    }
  }, [search, searchButtonInput, data]);

  return filteredItems;
};
