// App.tsx
import React from "react";
import Router from "./router/Router";
import { UserProvider } from "./providers/userProvider";
import { UserProfileProvider } from "./contexts/userProfileContext";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme/theme";
import { CssBaseline } from "@mui/material";
import { FirebaseProvider } from "./contexts/useFirebaseContect";


const App: React.FC = () => {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <UserProvider>
          <UserProfileProvider>
            <FirebaseProvider>
              <Router />
            </FirebaseProvider>
          </UserProfileProvider>
        </UserProvider>
      </ThemeProvider>
    </div>
  );
};

export default App;
