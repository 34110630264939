import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "../pages/home/Home";
import { Items } from "../pages/items/Items";
import Layout from "../components/Layout";
import { UserPage } from "../pages/user/UserPage";
import { UserItems } from "../pages/user/Items";
import { ItemDetail } from "../pages/items/ItemDetail";
import { LoginPage } from "../pages/login/login";
import { PrivateRoute } from "./private/PrivateRoute";
import { VerifiedRoute } from "./private/VerifiedRoute";
import { NotFound } from "../pages/404/404";
import { Profile } from "../pages/user/Profile";
import { UserItemDetail } from "../pages/user/UserItemDetail";
import { Create } from "../pages/user/Create";
import NewspaperPage from "../pages/articles/Articles";
import PasswordReset from "../pages/login/componants/PasswordReset";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { path: "", element: <Home /> },
      { path: "articles", element: <NewspaperPage /> },
      { path: "items", element: <Items /> },
      { path: "itemdetail/:itemId", element: <ItemDetail /> },
      { path: "user/login", element: <LoginPage /> },
      { path: "user/login/reset", element: <PasswordReset /> },
      {
        path: "user",
        element: <PrivateRoute />,
        children: [
          { path: "", element: <UserPage /> },
          { path: "profile", element: <Profile /> },
          {
            element: <VerifiedRoute />,
            children: [
              { path: "items", element: <UserItems /> },
              { path: "items/:itemId", element: <UserItemDetail /> },
              { path: "items/create", element: <Create /> },
              { path: "items/create/:id", element: <Create /> },
            ],
          },
        ],
      },
      { path: "*", element: <NotFound /> },
    ],
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
