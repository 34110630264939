import React from "react";
import { Box, Container, Typography, Link } from "@mui/material";

export const Footer: React.FC = () => {
  return (
    <Box
      component="footer"
      borderTop={1}
      sx={{
        py: 2,
        px: 2,
        
        bottom: 0, 
        width: "100%", 
       
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography variant="body1" color="textSecondary">
          ©2024
        </Typography>
        <Link
          href="https://www.freepik.com/free-photo/wooden-floor-background_4100933.htm#query=old%20newspaper%20background&position=22&from_view=keyword&track=ais&uuid=b711d33b-ab87-4777-b0cf-f4e751bd7c27"
          target="_blank"
          rel="noopener"
          color="inherit"
        >
          BackgroundImage by rawpixel.com on Freepik
        </Link>
      </Container>
    </Box>
  );
};
