import { useEffect, useState } from "react";
import { useUserContext } from "../contexts/userProviderContext";
import { firestoreDB } from "../firebase/firebase-config";
import {
  doc,
  getDoc,
  increment,
  updateDoc,
  deleteField,
  setDoc,
} from "firebase/firestore";

interface Item {
  id: string;
  name: string;
  likes: number;
}

const useItemLike = (itemId: string | undefined) => {
  const { user } = useUserContext();
  const [likes, setLikes] = useState<number>(0);
  const [likedByUser, setLikedByUser] = useState<boolean>(false);
  const [itemDetail, setItemDetail] = useState<Item | null>(null);

  useEffect(() => {
    if (!itemId) {
      console.error("Invalid itemId");
      return;
    }

    const itemRef = doc(firestoreDB, "Data", itemId);

    getDoc(itemRef)
      .then((doc) => {
        if (doc.exists()) {
          const itemData = doc.data() as Item;
          setItemDetail(itemData);
          setLikes(itemData.likes || 0);
        } else {
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });

    if (user) {
      const likedItemsRef = doc(firestoreDB, "likedItems", user.uid);
      getDoc(likedItemsRef).then((doc) => {
        if (doc.exists()) {
          const likedItems = doc.data();
          if (likedItems && likedItems[itemId]) {
            setLikedByUser(true);
          }
        }
      });
    }
  }, [itemId, user]);

  const toggleLike = () => {
    if (user && itemId) {
      const itemRef = doc(firestoreDB, "Data", itemId);
      const likedItemsRef = doc(firestoreDB, "likedItems", user.uid);

      if (likedByUser) {
        updateDoc(itemRef, { likes: increment(-1) });
        updateDoc(likedItemsRef, { [itemId]: deleteField() });
        setLikes(likes - 1);
      } else {
        updateDoc(itemRef, { likes: increment(1) });
        setDoc(likedItemsRef, { [itemId]: true }, { merge: true });
        setLikes(likes + 1);
      }

      setLikedByUser(!likedByUser);
    }
  };

  return { itemDetail, likes, likedByUser, toggleLike };
};
export { useItemLike };
