import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box, CardActionArea, IconButton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { FC, useEffect } from "react";
import { Loading } from "../../components/Loading";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  handleRemove,
  useFirebase,
} from "../../providers/firebaseProvider";
import { AlertComponent } from "../../components/Alerts";
import { useFilterDataByItemId } from "../../hooks/useFilterDataByItemId";  

export const UserItemDetail: FC = () => {
  const { itemId } = useParams<{ itemId: string }>();
  const { errorMessage, setErrorMessage, loading } = useFirebase();
  const filteredData = useFilterDataByItemId(itemId ?? "");
  const navigate = useNavigate();



  useEffect(() => {
    try {
      if (!itemId) {
        throw new Error("Item ID is missing.");
      }
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage("An unknown error occurred.");
      }
    }
  }, [itemId, filteredData, setErrorMessage]);

  if (loading && !errorMessage) {
    return <Loading />;
  }

  if (!filteredData) {
    return (
      <AlertComponent time={0} message="Item not found." severity="error" />
    );
  }
      const removeItem = () => {
        handleRemove(
          "Data",
          filteredData.docId,
          setErrorMessage,
          filteredData.image
        );
        navigate("/user/items");
      };
 
  return (
    <>
      <AlertComponent time={0} message={errorMessage} severity="error" />
      <Box display="flex" justifyContent="center" paddingTop={5} margin={1}>
        <Card>
          <CardActionArea onClick={() => window.open(filteredData?.image)}>
            <CardMedia
              component="img"
              image={filteredData.image}
              alt={filteredData.name}
              sx={{ height: { xs: 300, sm: 400, md: 500 } }}
            />
          </CardActionArea>
          <CardContent>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ height: "100%" }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Typography
                  gutterBottom
                  textAlign="center"
                  color="white"
                  variant="body1"
                  sx={{
                    fontSize: {
                      xs: "1rem",
                      sm: "1.4rem",
                      md: "1.8rem",
                      lg: "2rem",
                    },
                    marginRight: 1,
                  }}
                >
                  {filteredData.name}
                </Typography>

                {/* Buttons */}
                <Box
                  display="flex"
                  alignItems="center"
                  bgcolor="#CCCCCC"
                  borderRadius={4}
                  sx={{
                    padding: { xs: "4px", sm: "6px", md: "8px", lg: "10px" },
                    margin: { xs: "4px", sm: "6px", md: "8px", lg: "10px" },
                  }}
                >
                  {/* Create Button */}
                  <IconButton
                    sx={{
                      cursor: "pointer",
                      color: "#000000",
                      margin: 1,
                      fontSize: {
                        xs: "1rem",
                        sm: "1.4rem",
                        md: "1.8rem",
                        lg: "2rem",
                      },
                      "& .MuiSvgIcon-root": { fontSize: "inherit" },
                    }}
                    onClick={() =>
                      navigate(`/user/items/create/${filteredData.docId}`)
                    }
                  >
                    <CreateIcon />
                  </IconButton>
                  <IconButton
                    sx={{
                      cursor: "pointer",
                      color: "#000000",
                      margin: 1,
                      fontSize: {
                        xs: "1rem",
                        sm: "1.4rem",
                        md: "1.8rem",
                        lg: "2rem",
                      },
                      "& .MuiSvgIcon-root": { fontSize: "inherit" },
                    }}
                    onClick={() => removeItem()}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>

                <Typography
                  gutterBottom
                  textAlign="center"
                  color="white"
                  variant="body1"
                  sx={{
                    fontSize: {
                      xs: "1rem",
                      sm: "1.4rem",
                      md: "1.8rem",
                      lg: "2rem",
                    },
                    marginRight: 1,
                  }}
                >
                  uploaded by: {filteredData.userName}
                </Typography>
              </Box>
              <Typography variant="body2" color="white">
                {filteredData.comment}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};
