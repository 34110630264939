import { Typography } from "@mui/material";
import { useUserContext } from "../../contexts/userProviderContext";
import { useFilterDataByUserId } from "../../hooks/useFilterDataByUserId";


const UserPage: React.FC = () => {
    const user = useUserContext();
   

const filteredData = useFilterDataByUserId(user.user?.uid || "");
    
    const print = filteredData?.map(
      (item) => item.name
    );
        return (
          <Typography>
            {" "}
            UserPage logged in user {user.user?.uid}{print}

          </Typography>
        );
};

export { UserPage };