import React, { createContext, useContext, ReactNode } from "react";
import { useFirebase } from "../providers/firebaseProvider";



type FirebaseContextType = ReturnType<typeof useFirebase>;

const FirebaseContext = createContext<FirebaseContextType | null>(null);

export const FirebaseProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const firebaseContext = useFirebase();
  return (
    <FirebaseContext.Provider value={firebaseContext}>
      {children}
    </FirebaseContext.Provider>
  );
};

export const useFirebaseContext = (): FirebaseContextType => {
  const context = useContext(FirebaseContext);
  if (!context) {
    throw new Error(
      "usefirebaseContext must be used within a FirebaseProvider"
    );
  }
  return context;
};
