import { Box, Container, Grid, IconButton, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import { Loading } from "../../components/Loading";
import { useUserContext } from "../../contexts/userProviderContext";
import AddIcon from "@mui/icons-material/Add";
import { UserItem } from "./Item";
import { useNavigate } from "react-router-dom";
import { useFirebaseContext } from "../../contexts/useFirebaseContect";
import { handleRemove, } from "../../providers/firebaseProvider";
import { useFilterDataByUserId } from "../../hooks/useFilterDataByUserId";
import { AlertComponent } from "../../components/Alerts";
import { Data } from "../../interfaces/interfaces";
import { SearchFunction } from "../../components/searchFunction";


export const UserItems: FC = () => {  
  const { user } = useUserContext();
  const userId = user?.uid;
  const navigate = useNavigate();
  const pageTitle: string = "your regiments ";
  const { loading } = useFirebaseContext();
  const selectedItemsByUserId = useFilterDataByUserId(userId ?? "");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [filteredData, setFilteredData] = useState<Data[]>(selectedItemsByUserId ?? []) ;

    const handleFilteredDataChange = (filteredData: Data[]) => {
      setFilteredData(filteredData);
    };

  const removeDocument = async (docId: string, pictureFileName: string) => {
    await handleRemove("Data", docId, setErrorMessage, pictureFileName);
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <AlertComponent time={0} message={errorMessage} severity="error" />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ flexGrow: 2 }}
      >
        <SearchFunction
          data={selectedItemsByUserId ?? []}
          onFilteredDataChange={handleFilteredDataChange}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{
          border: "1px solid",
          borderColor: "#999999",
          overflow: "hidden",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.9)",
        }}
      >
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          sx={{
            marginLeft: {
              xs: "3rem",
              sm: 4,
              md: 5,
              lg: 15,
            },
          }}
        >
          <Typography
            display={"flex"}
            variant="h1"
            sx={{
              fontSize: {
                xs: "2rem",
                sm: "3rem",
              },
            }}
            textAlign="center"
          >
            {pageTitle}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          overflow="hidden"
          sx={{
            marginRight: { xs: "3rem", sm: 4, md: 5, lg: 8 },
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.9)",
          }}
        >
          <IconButton color="primary" onClick={() => navigate("create")}>
            <AddIcon sx={{ fontSize: "2rem" }} />
            <Typography
              variant="h3"
              ml={1}
              sx={{
                fontSize: {
                  xs: "1rem",
                  sm: "2rem",
                },
              }}
            >
              New regiment
            </Typography>
          </IconButton>
        </Box>
      </Box>

      <Box
        marginTop={2}
        marginBottom={1}
        display="flex"
        justifyContent="space-between"
        sx={{
          border: "1px solid",
          borderColor: "#999999",
          overflow: "hidden",
        }}
      ></Box>

      <Container maxWidth="xl" sx={{ overflow: "hidden" }}>
        <Grid container spacing={2} overflow="hidden">
          {filteredData?.map((item: Data, index: number) => (
            <Grid
              item
              key={index}
              xs={12}
              sm={6}
              md={4}
              lg={3}
              overflow="hidden"
            >
              <UserItem
                item={item}
                onRemove={(docId, pictureFilePath) =>
                  removeDocument(docId, pictureFilePath)
                }
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};
