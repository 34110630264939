import  { FC , useState} from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { Item } from "./Item";
import { Loading } from "../../components/Loading";
import { AlertComponent } from "../../components/Alerts";
import { useFirebaseContext } from "../../contexts/useFirebaseContect";
import { AlertSetUp, Data } from "../../interfaces/interfaces";
import { useNavigate } from "react-router-dom";
import { SearchFunction } from "../../components/searchFunction";


export const Items: FC = () => {
  const { data, loading, error } = useFirebaseContext();
  const pageTitle: string = "Warhammer regiments";
    const navigate = useNavigate();
  const [alertSetUp, setAlertSetUp] = useState<AlertSetUp>({
    time: 0,
    message: "",
    severity: "error",
  });

  const [filteredData, setFilteredData] = useState<Data[]>(data);

  const handleFilteredDataChange = (filteredData: Data[]) => {
    setFilteredData(filteredData);
  };


      const handleClick = (docId : string) => {
        if (docId) {
          navigate(`/itemdetail/${docId}`);
          if (!docId) {
            console.error("no docId");
            setAlertSetUp({time: 2000, message: "the regiment is not found , try again later", severity: "error"});
          }
        }
  };




  

  if (error) {
    setAlertSetUp({
      time: 2000,
      message: "error : " + error,
      severity: "error",
    });
  }

  if (loading ) {
    return <Loading />;
  }

  return (
    <>
      <AlertComponent
        message={alertSetUp.message}
        time={alertSetUp.time}
        severity={alertSetUp.severity}
        buttonPressedAlert={alertSetUp.buttonPressedAlert}
      />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ flexGrow: 2 }}
      >
        <SearchFunction
          data={data}
          onFilteredDataChange={handleFilteredDataChange}
        />
      </Box>
      <Box
        display="flex"
        marginBottom={2}
        justifyContent="space-between"
        sx={{ border: "1px solid", borderColor: "grey.500" }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ flexGrow: 2 }}
        >
          <Typography
            display={"flex"}
            variant="h1"
            sx={{
              fontSize: {
                xs: "2rem",
                sm: "3rem",
              },
            }}
            textAlign="center"
          >
            {pageTitle}
          </Typography>
        </Box>
      </Box>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          {filteredData.map((item: Data, index: number) => (
            <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
              <Item
                item={item}
                handleClick={handleClick}
                alertSetup={(alertProps: AlertSetUp) =>
                  setAlertSetUp(alertProps)
                }
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};
