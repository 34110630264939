import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Image from "../../images/warhammerLogo1.png";

export const NotFound: React.FC = () => {
  const navigate = useNavigate();
  const handleGoHomeClick = () => {
    navigate("/");
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      textAlign="center"
      p={2}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          width: { xs: 300, sm: 350, md: 400 },
          height: "auto",
          marginBottom: 2,
        }}
      >
        <img
          src={Image}
          alt="Warhammer Logo"
          style={{
            width: "100%",
            height: "auto",
            maxWidth: "100%",
            objectFit: "contain",
          }}
        />
      </Box>

      <Typography variant="h2" gutterBottom>
        404 Not Found
      </Typography>
      <Typography paragraph>
        Oops! Looks like you've ventured into uncharted territory.
      </Typography>
      <Typography paragraph>
        Don't worry, our crack team of hamsters is on the case. In the meantime,
        let's go back home and try again.
      </Typography>
      <Button variant="contained" color="primary" onClick={handleGoHomeClick}>
        Go Home
      </Button>
    </Box>
  );
};
