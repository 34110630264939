import { initializeApp } from "firebase/app";
import {  getFirestore } from "firebase/firestore";
import { browserLocalPersistence, getAuth, setPersistence } from "firebase/auth";
import { getStorage } from "firebase/storage";



const firebaseConfig = {
  
  apiKey: "AIzaSyAqxNLnIREBL_NT1rQY1fJCxeAp-PC4rUI",
  authDomain: "warhammer-times-test.firebaseapp.com",
  projectId: "warhammer-times-test",
  storageBucket: "warhammer-times-test.appspot.com",
  messagingSenderId: "75378469689",
  appId: "1:75378469689:web:777827eb2ce657684e16e8",
};
export const firebaseApp = initializeApp(firebaseConfig);
export const firestoreDB = getFirestore(firebaseApp);
export const firebaseAuth = getAuth(firebaseApp);
export const storage = getStorage(firebaseApp);

  setPersistence(firebaseAuth, browserLocalPersistence);
