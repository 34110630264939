import React from "react";
import { Container, Grid, Typography, Paper, Box } from "@mui/material";
import { styled } from "@mui/system";

const OldPaper = styled(Paper)({
  backgroundColor: "#f5deb3",
  padding: "20px",
  fontFamily: "Times New Roman, Times, serif",
  color: "#333",
  boxShadow: "none",
  borderRadius: 0,
  border: "1px solid #ccc",
  lineHeight: 1.6,
});

const ArticleTitle = styled(Typography)({
  fontSize: "24px",
  fontWeight: "bold",
  marginBottom: "10px",
});

const Subtitle = styled(Typography)({
  fontSize: "20px",
  fontWeight: "bold",
  marginBottom: "5px",
});

const articleData = [
 
  {
    title: "The Doom of the Dwarfs: A Tragic Tale",
    subtitle: "Karaz-a-Karak, the Great Hold",
    content:
      "In the darkness of the World’s Edge Mountains, the Dwarfs face a grim future. This article recounts the events leading to the fall of Karaz-a-Karak, once the mightiest of the Dwarf holds. Betrayal, ancient grudges, and the relentless march of enemies threaten to extinguish the last embers of Dwarf glory. Despite their indomitable spirit, can the Dwarfs defy their doom and reclaim their lost honor?",
    size: 6,
    rowSpan: 2,
  },
  {
    title: "The Wrath of Chaos: The Siege of Kislev",
    subtitle: "The Northern Frontiers",
    content:
      "As winter descends upon the lands of Kislev, the forces of Chaos gather for a final assault. This gripping account chronicles the harrowing siege of Kislev, where the defenders stand as the last bulwark against the tide of darkness. With the fate of the Old World hanging in the balance, heroes emerge amidst the snow and blood, determined to defy the chaos gods and protect their homeland at any cost.",
    size: 6,
    rowSpan: 2,
  },
  {
    title: "Ancient Alliances: The High Elves and Their Everlasting Vigil",
    subtitle: "Ulthuan, the Isle of the Asur",
    content:
      "The High Elves, led by the Phoenix King, remain ever-vigilant against the forces of Chaos. For millennia, these noble beings have safeguarded the world, their mages weaving powerful enchantments to protect their lands. This detailed account covers their ancient alliances, formidable defenses, and the mystical arts they employ to keep Ulthuan safe from invaders. The High Elves’ dedication to their cause and the sacrifices they’ve made highlight their enduring commitment to the preservation of order and beauty in a world threatened by darkness.",
    size: 6,
  },
  {
    title: "The Rise of Man: The Empire Stands Firm",
    subtitle: "Altdorf, Capital of The Empire",
    content:
      "Under the stern leadership of Emperor Karl Franz, The Empire has stood as a bastion against the darkness threatening the Old World. Learn about the intricate politics, military strategies, and the unbreakable will of humanity that keeps the Empire strong against all odds. This in-depth look at the Empire covers its history, the various provinces, and the key figures who have shaped its destiny. From the bustling cities to the rural heartlands, the spirit of the Empire’s people and their unwavering resolve in the face of constant threat is brought to life.",
    size: 12,
    rowSpan: 2,
  },
  {
    title: "The Unending Night: Tales from Naggaroth",
    subtitle: "Naggaroth, the Land of Chill",
    content:
      "The Dark Elves, or Druchii, are known for their cruelty and mastery of dark sorcery. This piece explores the history of their exile from Ulthuan, their sinister society, and the Witch King Malekith's relentless quest for vengeance and power. The article provides a chilling insight into the treacherous politics, brutal military campaigns, and the dark rituals that define life in Naggaroth. The ambition and ruthlessness of the Dark Elves, coupled with their unquenchable thirst for power, paint a grim picture of a society built on pain and suffering.",
    size: 6,
  },
  {
    title: "Waaagh! The Orks and Goblins on the March",
    subtitle: "Badlands",
    content:
      "The Greenskin tribes, led by brutal Warbosses, are ever eager for a good fight. This article chronicles their most recent campaigns, the chaotic structure of their society, and the savage ingenuity of their warfare. From the raucous celebrations of a successful Waaagh! to the brutal skirmishes that define their existence, the life of an Ork is one of constant conflict and raw power. The unpredictable nature of the Greenskins and their ceaseless drive for battle make them a terrifying force to be reckoned with in the Warhammer world.",
    size: 6,
  },
  {
    title: "Vampire Counts: The Night’s Undying Lords",
    subtitle: "Sylvania",
    content:
      "In the heart of the Empire lies Sylvania, a land shrouded in darkness and ruled by the immortal Vampire Counts. Discover the terrifying history of these undead lords, their necromantic powers, and their endless thirst for dominion over the living. The article delves into the macabre rituals, the complex web of alliances and enmities, and the dark ambitions that drive the Vampire Counts. From the haunted castles to the cursed battlegrounds, the eerie landscape of Sylvania is brought to life, revealing a realm where death is just the beginning.",
    size: 12,
    rowSpan: 2,
  },
  {
    title: "Skaven: The Under-Empire’s Insidious Threat",
    subtitle: "The Under-Empire",
    content:
      "Beneath the surface of the world, the Skaven plot and scheme, ready to unleash havoc. This investigative report uncovers the depths of their underground empire, their technological and magical advancements, and the ever-present danger they pose to the surface world. The Skaven’s insidious nature, their cunning plans, and the constant infighting that characterizes their society are examined in detail. The sheer scale of the Under-Empire and the Skaven’s relentless drive to spread corruption and chaos make them a fearsome adversary.",
    size: 6,
  },
  {
    title: "The Lizardmen: Guardians of the Old Ones’ Legacy",
    subtitle: "Lustria, the Great Jungle",
    content:
      "In the steamy jungles of Lustria, the Lizardmen stand watch over the ancient ruins of their creators. This article explores their enigmatic society, their reverence for the Old Ones, and the powerful magic that sustains their civilization. The Lizardmen’s unwavering dedication to their sacred duty, their fierce defense of Lustria, and the mysterious prophecies that guide their actions are revealed. The intricate rituals, the mighty temple-cities, and the formidable saurian warriors paint a vivid picture of a  ",
    size: 6,
  },
  {
    title: "The Great War of Chaos: Cataclysm in the North",
    subtitle: "The Chaos Wastes",
    content:
      "The Great War of Chaos has erupted once again, plunging the northern lands into turmoil. This article delves into the relentless advance of the Chaos hordes, their insatiable hunger for destruction, and the desperate battles fought by the defenders. As the very fabric of reality is torn asunder, heroes emerge to confront the dark forces threatening to consume the world. Will the armies of Order stand firm against the tide of Chaos, or will the world be plunged into eternal darkness?",
    size: 6,
  },
  {
    title: "The Elven Exodus: Flight from Ulthuan",
    subtitle: "The Sundering Seas",
    content:
      "In the wake of the great sundering, the High Elves flee their shattered homeland in search of sanctuary. This article chronicles their perilous journey across the seas, beset by storms, monsters, and the ever-looming threat of Chaos. As they carve out new realms in distant lands, the High Elves face internal strife and external adversaries, testing their resilience and determination. Can they find a new home and rebuild their shattered civilization, or are they doomed to wander as exiles forever?",
    size: 6,
  },
  {
    title: "The Rise of Nagash: Lord of Undeath",
    subtitle: "Nehekhara, Land of the Dead",
    content:
      "From the sands of Nehekhara arises a figure of dread: Nagash, the first necromancer. This article delves into his dark origins, his insatiable thirst for power, and the sinister rituals that grant him dominion over death itself. As he raises legions of undead to serve his will, Nagash threatens to plunge the world into eternal darkness. Only the bravest heroes dare to oppose him, for the fate of all the living hangs in the balance.",
    size: 6,
  },
  {
    title: "The Siege of Altdorf: The Empire Under Fire",
    subtitle: "Altdorf, Heart of the Empire",
    content:
      "The grand capital of the Empire faces its greatest challenge yet: the full might of Chaos unleashed upon its walls. This article provides a harrowing account of the siege, from the initial assault to the desperate defense by Emperor Karl Franz and his valiant soldiers. As the city burns and its people suffer, hope flickers like a candle in the wind. Will Altdorf stand firm against the forces of darkness, or will it be consumed by the flames of war?",
    size: 12,
  },
  {
    title: "The Curse of the Vampire Coast: Dread Pirates and Ghost Ships",
    subtitle: "The Mists of Sartosa",
    content:
      "Along the treacherous coastlines of the Old World, a new terror rises: the Vampire Coast. This article explores the haunted seas, where ghostly ships crewed by the undead prowl the waves in search of prey. Led by the infamous vampire lords, these dread pirates raid coastal settlements, spreading terror and death in their wake. As the living cower in fear, brave adventurers set sail to confront the vampire scourge and reclaim the seas for humanity.",
    size: 6,
  },
  {
    title: "The Eternal War: Chaos vs. Order",
    subtitle: "The Balance of Power",
    content:
      "In the endless struggle between Chaos and Order, the fate of the world hangs in the balance. This article examines the eternal war, from the realms of the Chaos Gods to the bastions of civilization. Heroes and villains clash on blood-soaked battlefields, while dark powers scheme and plot in the shadows. As the forces of Chaos seek to engulf the world in darkness, champions of Order rise to oppose them, forging alliances and rallying armies to stem the tide of destruction.",
    size: 6,
  },
];

const NewspaperPage: React.FC = () => {
  return (
    <Container>
      <Box mt={3} mb={3}>
        <Typography variant="h2" align="center" gutterBottom>
          The Warhammer Times
        </Typography>
      </Box>
      <Grid container spacing={3} justifyContent="center">
        {articleData.map((article, index) => (
          <Grid
            item
            key={index}
            xs={12}
            sm={6}
            md={6}
            lg={article.size ?? 3} 
          >
            <OldPaper>
              <ArticleTitle>{article.title}</ArticleTitle>
              <Subtitle>{article.subtitle}</Subtitle>
              <Typography>{article.content}</Typography>
            </OldPaper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default NewspaperPage;
