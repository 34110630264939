import React, { useEffect, useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { Box, Button, TextField, Typography } from "@mui/material";
import { AlertComponent } from "../../../components/Alerts";
import { AlertSetUp } from "../../../interfaces/interfaces";
import { useNavigate } from "react-router-dom";

const PasswordReset: React.FC = () => {
  const [email, setEmail] = useState("");
    const [buttonClicked, setButtonClicked] = useState(false);
    const [alertSetup , setAlertSetUp] = useState<AlertSetUp>({ time: 0, message: "", severity: "error" });
    const auth = getAuth();
    const navigate = useNavigate();
    

    const handleReset = async () => {

        setButtonClicked(true);
        try {
            if (!email) {
                setAlertSetUp({
                    time: 2000,
                    message: "please enter a email adress",
                    severity: "error",
                });
                setButtonClicked(false);
                return
            }
            await sendPasswordResetEmail(auth, email);
            setAlertSetUp({ time: 2000, message: "Password reset request send", severity: "success" })
        }
        catch (error) {
            if (error instanceof Error) {
                setAlertSetUp({
                    time: 2000,
                    message: error.message,
                    severity: "error",
                });
                console.error(error);
            }
        } finally {
            setButtonClicked(false);
            
        }
  };

  return (
    <>
      <AlertComponent
        message={alertSetup.message}
        time={alertSetup.time}
        severity={alertSetup.severity}
        buttonPressedAlert={buttonClicked}
      />

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width={1}
        padding={2}
        height="100vh"
      >
        <Box marginBottom={2}>
          <Typography variant="h4">Reset your Password</Typography>
        </Box>
        <TextField
          required
          variant="outlined"
          label="Email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          margin="normal"
          sx={{ maxWidth: 400 }}
          fullWidth
        />
        <Box mt={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleReset}
            fullWidth
          >
            Reset Password
          </Button>
          <Box margin={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => navigate("/user/login")}
            >
              Back to Login
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PasswordReset;
