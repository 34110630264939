import {
  Box,
  Button,
  CircularProgress,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import { useUserProfileContext } from "../../contexts/userProfileContext";
import {
  Timestamp,
  DocumentData,
} from "firebase/firestore";
import { ChangeEvent, FC, useEffect, useReducer, useState } from "react";
import { getCurrentTimestamp } from "../../components/getCurrentTimestamp";
import { generateId } from "../../components/generateId";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "../../components/Loading";
import { AlertComponent } from "../../components/Alerts";
import { AlertProps } from "@mui/material";
import {
  handleUpload,
  removeFileFromStorage,
  uploadOrUpdateDocument,
} from "../../providers/firebaseProvider";
import { useFilterDataByItemId } from "../../hooks/useFilterDataByItemId";

type State = {
  docId?: string;
  name: string;
  army: string;
  id: string;
  userName: string | undefined;
  userId: string | undefined;
  timestamp: Timestamp;
  image: string;
  comment: string;
  loading?: boolean;
  uploading?: boolean;
  file?: File | null;
  pictureFileName?: string;
};

type ItemAction =
  | { type: "SET_DOC_ID"; payload: string }
  | { type: "SET_NAME"; payload: string }
  | { type: "SET_ARMY"; payload: string }
  | { type: "SET_ID"; payload: string }
  | { type: "SET_USER_NAME"; payload: string | undefined }
  | { type: "SET_USER_ID"; payload: string | undefined }
  | { type: "SET_TIMESTAMP"; payload: Timestamp }
  | { type: "SET_IMAGE"; payload: string }
  | { type: "SET_COMMENT"; payload: string }
  | { type: "SET_LOADING"; payload: boolean }
  | { type: "SET_FILE"; payload: File | null }
  | { type: "SET_UPLOADING"; payload: boolean }
  | { type: "SET_PICTURE_FILE_NAME"; payload: string }
  | { type: "RESET_FORM" }
  | {
      type: "SET_DATA";
      payload: {
        docId?: string;
        name: string;
        army: string;
        id: string;
        userName: string | undefined;
        userId: string | undefined;
        timestamp: Timestamp;
        image: string;
        comment: string;
        pictureFileName: string;
      };
    };
export type Severity = AlertProps["severity"];

const initialState: State = {
  docId: "",
  name: "",
  army: "",
  id: "",
  userName: "",
  userId: "",
  timestamp: getCurrentTimestamp(),
  image: "",
  comment: "",
  file: null,
  pictureFileName: "",
};

const reducer = (state: State, action: ItemAction): State => {
  switch (action.type) {
    case "SET_NAME":
      return { ...state, name: action.payload };
    case "SET_ARMY":
      return { ...state, army: action.payload };
    case "SET_ID":
      return { ...state, id: action.payload };
    case "SET_USER_NAME":
      return { ...state, userName: action.payload };
    case "SET_COMMENT":
      return { ...state, comment: action.payload };
    case "SET_USER_ID":
      return { ...state, userId: action.payload };
    case "SET_TIMESTAMP":
      return { ...state, timestamp: action.payload };
    case "SET_IMAGE":
      return { ...state, image: action.payload };
    case "SET_UPLOADING":
      return { ...state, uploading: action.payload };
    case "SET_FILE":
      return { ...state, file: action.payload };
    case "RESET_FORM":
      return initialState;
    case "SET_DATA":
      return { ...action.payload };
    case "SET_LOADING":
      return { ...state, loading: action.payload };
    case "SET_PICTURE_FILE_NAME":
      return { ...state, pictureFileName: action.payload };
    default:
      return state;
  }
};

export const Create: FC = () => {
  const { id } = useParams<{ id: string }>();
  const itemId = id;
  const [state, dispatch] = useReducer(reducer, initialState);
  const user = useUserProfileContext();
  const navigate = useNavigate();
  const { userName, userId } = user;
  const filteredData = useFilterDataByItemId(itemId ?? "");
  const uniqueId = generateId();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const[buttonPressed, setButtonPressed] = useState<boolean>(false);
  const [alertSetUp, setAlertSetUp] = useState<{
    time: number;
    message: string;
    severity: Severity;
  }>({ time: 0, message: "", severity: "success" });
 
  useEffect(() => {
    dispatch({ type: "SET_USER_ID", payload: userId });
    dispatch({ type: "SET_USER_NAME", payload: userName });
  }, [userId, userName]);

  useEffect(() => {
    if (itemId && filteredData) {
      dispatch({ type: "SET_DOC_ID", payload: filteredData.docId });
      dispatch({ type: "SET_NAME", payload: filteredData.name });
      dispatch({ type: "SET_ARMY", payload: filteredData.army });
      dispatch({ type: "SET_COMMENT", payload: filteredData.comment });
      dispatch({ type: "SET_IMAGE", payload: filteredData.image });
      dispatch({ type: "SET_USER_ID", payload: filteredData.userId });
      dispatch({ type: "SET_USER_NAME", payload: filteredData.userName });
      dispatch({ type: "SET_ID", payload: filteredData.id });
      dispatch({ type: "SET_PICTURE_FILE_NAME", payload: filteredData.pictureFileName });
    }
  }, [itemId, filteredData]);

      useEffect(() => {
        if (buttonPressed) setButtonPressed(false);
      }, [buttonPressed]);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      dispatch({ type: "SET_FILE", payload: event.target.files[0] });
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    dispatch({ type: "SET_UPLOADING", payload: true });
    setButtonPressed(true);
   
    if (!itemId && state.file) {
      
      try {

        const fileName = `${uniqueId}-${state.file?.name}`;
        const url = await handleUpload(fileName, state.file);
        dispatch({ type: "SET_IMAGE", payload: url ?? "" });
        dispatch({ type: "SET_PICTURE_FILE_NAME", payload: fileName });
        const newData: DocumentData = {
          name: state.name,
          army: state.army,
          pictureFileName: fileName,
          userName: state.userName,
          userId: state.userId,
          timestamp: Timestamp.now(),
          image: url,
          comment: state.comment,
        };
        await uploadOrUpdateDocument("Data", null, newData);
      } catch (error) {
        console.log("Error adding document: ", error);
      }
      finally {
        dispatch({ type: "SET_UPLOADING", payload: false });
        setAlertSetUp({ time: 2000, message: "regiment is added to your collection", severity: "success" });
        navigate("/user/items");
      }
    }

    else if (!itemId && !state.file) {
      try {
        const newData: DocumentData = {
          name: state.name,
          army: state.army,
          pictureFileName: null,
          userName: state.userName,
          userId: state.userId,
          timestamp: getCurrentTimestamp(),
          image:
            "https://upload.wikimedia.org/wikipedia/commons/d/d1/Image_not_available.png",
          comment: state.comment,
        };
        await uploadOrUpdateDocument("Data", null, newData);
      } catch (error) {
        console.log("Error adding document: ", error);
        setErrorMessage("Error adding document: " + (error as Error).message);
        setAlertSetUp({ time: 2000, message: errorMessage, severity: "error" });
      } finally {
        dispatch({ type: "SET_UPLOADING", payload: false });
        setAlertSetUp({
          time: 2000,
          message: "regiment is added to your collection",
          severity: "success",
        });
        navigate("/user/items");
      }
    }


if (itemId) {
  if (state.file !== null && state.file !== undefined) {
    const oldFileName: string | undefined = state.pictureFileName;
    if (oldFileName) {
      await removeFileFromStorage(`images/${oldFileName}`);
    }    
    const fileName = `${uniqueId}-${state.file?.name}`;
    const url = await handleUpload(fileName, state.file);
    dispatch({ type: "SET_IMAGE", payload: url ?? "" });
    dispatch({ type: "SET_PICTURE_FILE_NAME", payload: fileName });
    try {
      const docIdToUpdate = itemId;
      const updatedData: DocumentData = {
        name: state.name,
        army: state.army,
        id: "",
        userName: state.userName,
        userId: state.userId,
        timestamp: state.timestamp,
        image: url,
        comment: state.comment,
        pictureFileName: fileName,
      };
      await uploadOrUpdateDocument("Data", docIdToUpdate, updatedData);
    } catch (error) {
      console.log("Error updating document: ", error);
      setErrorMessage("Error updating document: " + (error as Error).message);
      setAlertSetUp({
        time: 2000,
        message: errorMessage,
        severity: "error",
      });
    } finally {
      dispatch({ type: "SET_UPLOADING", payload: false });
      setAlertSetUp({
        time: 2000,
        message: "regiment is updated successfully",
        severity: "success",
      });
    }
  } else {
    try {
      const docIdToUpdate = itemId;
      const updatedData: DocumentData = {
        name: state.name,
        army: state.army,
        id: "",
        userName: state.userName,
        userId: state.userId,
        timestamp: state.timestamp,
        image: state.image,
        comment: state.comment,
        pictureFileName: state.pictureFileName || "",
      };
      await uploadOrUpdateDocument("Data", docIdToUpdate, updatedData);
    } catch (error) {
      console.log("Error updating document: ", error);
      setErrorMessage("Error updating document: " + (error as Error).message);
      setAlertSetUp({ time: 2000, message: errorMessage, severity: "error" });
    } finally {
      dispatch({ type: "SET_UPLOADING", payload: false });
      setAlertSetUp({
        time: 2000,
        message: "regiment is updated succesfully",
        severity: "success",
      });
    }
  }
    }
  };

  if (state.loading) {
    return <Loading />;
  }

  return (
    <>
      <AlertComponent
        message={alertSetUp.message}
        time={alertSetUp.time}
        severity={alertSetUp.severity}
        buttonPressedAlert={buttonPressed}
      />
      <Container maxWidth="sm">
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Typography variant="h6">
            {itemId ? "Update Regiment" : "Add Regiment"}
          </Typography>
          <TextField
            label="Name"
            value={state.name}
            onChange={(e) =>
              dispatch({ type: "SET_NAME", payload: e.target.value })
            }
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Army"
            value={state.army}
            onChange={(e) =>
              dispatch({ type: "SET_ARMY", payload: e.target.value })
            }
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Comment"
            value={state.comment}
            onChange={(e) =>
              dispatch({ type: "SET_COMMENT", payload: e.target.value })
            }
            fullWidth
            margin="normal"
            multiline
            rows={4}
          />
          <Box>
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="file-input"
              type="file"
              onChange={handleFileChange}
            />
            <label htmlFor="file-input">
              <Button variant="contained" component="span">
                Select File
              </Button>
            </label>
            {state.file && (
              <Box
                mt={2}
                border={1}
                borderRadius={4}
                boxShadow={3}
                color="secondary.main"
                p={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="body1">
                  Selected file: {state.file.name}
                </Typography>
              </Box>
            )}
            {state.uploading && <CircularProgress />}
          </Box>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            {itemId ? "Update" : "Submit"}
          </Button>
        </Box>
      </Container>
    </>
  );
};
