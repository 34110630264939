import { MouseEvent, useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Logo from "../images/warhammerLogo1.png";
import dwarfHelmet from "../images/Imperial_Cross.webp";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../contexts/userProviderContext";
import { useUserProfileContext } from "../contexts/userProfileContext";

type Link = {
  displayName: string;
  path: string;
};

function ResponsiveAppBar() {
  const { userName } = useUserProfileContext();
  const { user, logout } = useUserContext();
  const [pages, setPages] = useState<Link[]>([
    { displayName: "Items", path: "/items" },
    { displayName: "Articles", path: "/Articles" },
  ]);

  const settings: Link[] = [
    { displayName: "Profile", path: "/user/profile" },
    { displayName: "Dashboard", path: "user/items" },
    { displayName: "Logout", path: "logout" },
  ];
  useEffect(() => {
    if (user) {
      setPages((prevPages) =>
        prevPages.filter((page) => page.displayName !== "Login")
      );
    } else {
      setPages((prevPages) => {
        if (!prevPages.some((page) => page.displayName === "Login")) {
          return [...prevPages, { displayName: "Login", path: "/user/login" }];
        }
        return prevPages;
      });
    }
  }, [user]);

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const navigate = useNavigate();

  const handleClick = (path: string) => {
    return () => {
      navigate(path);
      handleCloseNavMenu();
    };
  };

  const HandleLogoutClick = async (): Promise<void> => {
    await logout();
    console.log("logout");
  };

  const handleClickSetting = (setting: string): void => {
    setting === "logout" ? HandleLogoutClick() : navigate(setting);
    handleCloseUserMenu();
  };

  const IsLoggedIn = () => {
    const { user } = useUserContext();
    const response = user === undefined ? false : true;
    return response;
  };
  const printUserMenu = IsLoggedIn() ? (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar
            alt={userName}
            src={dwarfHelmet}
            sx={{
              width: 60, // Set the width to your desired size
              height: 60, // Set the height to your desired size
            }}
          />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {settings.map((setting) => (
          <MenuItem
            key={setting.displayName}
            onClick={() => handleClickSetting(setting.path)}
          >
            <Typography textAlign="center" color="#FFFFF0">
              {setting.displayName}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  ) : (
    <Box></Box>
  );

  return (
    <AppBar
      position="static"
      sx={{
        borderRadius: 0,
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            onClick={handleClick("/")}
            component="img"
            sx={{
              height: 64,
            }}
            alt="Warhammer Logo"
            src={Logo}
          />

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.displayName}
                  onClick={handleClick(page.path)}
                >
                  <Typography textAlign="center" color="#FFFFF0">
                    {page.displayName}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page.displayName}
                onClick={handleClick(page.path)}
                sx={{ my: 2, color: "#FFFFF0", display: "block" }}
              >
                {page.displayName}
              </Button>
            ))}
          </Box>
          <Box display="flex" justify-content="center" sx={{ flexGrow: 2 }}>
            <Typography
              variant="h2"
              sx={{ display: { xs: "flex", sm: "none" }, fontSize: "1rem" }}
              textAlign="center"
              color="#FFFFF0"
            >
              The Warhammer Times
            </Typography>
            <Typography
              variant="h1"
              sx={{ display: { xs: "none", sm: "flex" } }}
              textAlign="center"
              color="#FFFFF0"
            >
              The Warhammer Times
            </Typography>
          </Box>
          {printUserMenu}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
