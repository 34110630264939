import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useUserContext } from "../../contexts/userProviderContext";

export const VerifiedRoute: React.FC = () => {
  const { user, isLoading } = useUserContext();

  if (isLoading) {
    return <div>Loading...</div>; // Or use a better loading indicator
  }

  if (!user) {
    return <Navigate to="/user/login" />;
  }

  if (!user.emailVerified) {
    return <Navigate to="/user/profile" />;
  }

  return <Outlet />;
};
