import {
  DocumentData,
  Unsubscribe,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  FirestoreError,
} from "firebase/firestore";
import {  useEffect, useState } from "react";
import { firestoreDB, storage } from "../firebase/firebase-config";
import { Data } from "../interfaces/interfaces";
import { HookState } from "../interfaces/interfaces";
import { generateId } from "../components/generateId";
import { deleteObject, getDownloadURL, ref, uploadBytes } from "firebase/storage";

const FetchFirestoreData = (): HookState => {
  const [data, setData] = useState<Data[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const collectionRef = collection(firestoreDB, "Data");
        const dataQuery = query(collectionRef);
        const unsubscribe: Unsubscribe = onSnapshot(dataQuery, (snapshot) => {
          const newData = snapshot.docs.map(
            (doc) => ({ docId: doc.id, ...doc.data() } as Data)
          );
          setData(newData);
          setLoading(false);
          setError(null);
        });
        return () => unsubscribe();
      } catch (error: any) {
        console.error("Error fetching data:", error);
        setData([]);
        setLoading(false);
        setError(error as Error);
      }
    };

    fetchData();

    return () => {};
  }, []);

  return { data, loading, error };
};

export const useFirebase = () => {
  const { data, loading, error } = FetchFirestoreData();
  const [selectedItemsByUserId, setSelectedItemsByUserId] = useState<
    Data[] | null
  >(null);
  const [selectedItemByItemId, setSelectedItemByItemId] = useState<Data | null>(
    null
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  return {
    data,
    loading,
    error,
    selectedItemsByUserId,
    setSelectedItemsByUserId,
    selectedItemByItemId,
    setSelectedItemByItemId,
    errorMessage,
    setErrorMessage,
  };
};





export const handleRemove = async (
  collection: string,
  docId: string,
  setErrorMessage: (message: string | null) => void,
  pictureFileName: string | undefined 
) => {
  try {
    const docRef = doc(firestoreDB, collection, docId);
    await deleteDoc(docRef);
  
    await removeFileFromStorage(`images/${pictureFileName}`);
    console.log("Document handleRemope pictuer filename :", pictureFileName);
     console.log("Document handleRemope filePath:");
    
    console.log(`Document with ID ${docId} successfully deleted.`);
  } catch (error) {
    if (error instanceof Error) {
      setErrorMessage(error.message);
    } else {
      setErrorMessage("An unknown error occurred.");
    }
    console.error("Error removing document: ", error);
  }
};


export const removeFileFromStorage = async (
  filePath: string
): Promise<string> => {
  try {
    const fileRef = ref(storage, filePath);
    await deleteObject(fileRef);
    console.log("File deleted successfully from storage.");
    return "File deleted successfully from storage.";
  } catch (error) {
    const err = error as FirestoreError;
    console.error("Error deleting file from storage:", err.message);
    return `Error deleting file from storage: ${err.message}`;
  }
 
};






export const uploadOrUpdateDocument = async (
  collectionName: string,
  docId: string | null,
  data: Data | DocumentData
) => {
  try {
    if (docId) {
      const collectionRef = doc(firestoreDB, collectionName, docId);
      await updateDoc(collectionRef, data);
      console.log(`Document with ID ${docId} successfully updated.`);
    } else {
      const collectionRef = doc(firestoreDB, collectionName, generateId());
      await setDoc(collectionRef, data);
      console.log("New document successfully added.");
    }
  } catch (error) {
    console.error("Error uploading or updating document: ", error);
    throw error; 
  }
};

export const handleUpload = async (
  fileName: string,
  file: File
): Promise<string | null> => {
  if (!file) return null;



  try {
    const storageRef = ref(storage, `images/${fileName}`);
    const snapshot = await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(snapshot.ref);
    console.log("File uploaded successfully:", downloadURL);
    return downloadURL;
  } catch (error) {
    if (error instanceof Error) {
      console.error(
        "Error uploading image and saving URL to Firestore:",
        error.message
      );
      return error.message;
    } else {
      console.error("Unknown error occurred:", error);
      return "Unknown error occurred";
    }
  } finally {
    //setUploading(false);
  }
};




