import { User } from "firebase/auth";
import { createContext, useContext } from "react";

export type UserContextType = {
  user?: User;
  isLoading: boolean;
  signup: (
    email: string,
    password: string
  ) => Promise<{ user?: User; error?: string }>;
  signin: (
    email: string,
    password: string
  ) => Promise<{ user?: User; error?: string }>;
  logout: () => Promise<void>;
  buttonPressed: boolean;
  setButtonPressed: (value: boolean) => void;
};

const UserContext = createContext<UserContextType>({
  signup: async () => ({}),
  signin: async () => ({}),
  logout: async () => {},
  isLoading: true,
  buttonPressed: false,
  setButtonPressed: () => {},
});

export const useUserContext = (): UserContextType => {
  return useContext(UserContext);
};

export default UserContext;
