import { useFirebaseContext } from "../contexts/useFirebaseContect";

export const useRandomImage = (): string => {
 const { data } = useFirebaseContext();
  if (!data || data.length === 0) {
    return "";
  }
  const randomIndex = Math.floor(Math.random() * data.length);
  const randomItem = data[randomIndex];
  const randomImage = randomItem.image;
  return randomImage;
};
