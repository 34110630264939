import {
  Box,
  IconButton,
  ImageListItem,
  ImageListItemBar,
  Link,
  Typography,
} from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { Data } from "../../interfaces/interfaces";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { useItemLike } from "../../hooks/useItemLike";

interface ItemProps {
  item: Data;
  onRemove: (docId: string, pictureFileName: string) => void;
}


export const UserItem: React.FC<ItemProps> = ({ item, onRemove }) => {
  const navigate = useNavigate();
  const { likes } = useItemLike(item?.docId);


  return (
    <Box
      sx={{
        minWidth: { lg: "150px", md: "150px", sm: "150px" },
        maxWidth: { lg: "450px", md: "250px", sm: "200px" },
        minHeight: { lg: "84px", md: "84px", sm: "84px" },
        maxHeight: { lg: "253px", md: "141px", sm: "113px" },
        margin: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 1,
        boxShadow: 3,
        borderRadius: 0,
        overflow: "hidden",
      }}
    >
      <Box
        display="flex"
        border={1}
        width="100%"
        sx={{ color: "rgba(255, 255, 255, 0.54)" }}
      >
        <Link
          underline="none"
          sx={{ cursor: "pointer", flexGrow: 1 }}
          onClick={() => navigate(`/user/items/${item.docId}`)}
        >
          <ImageListItem
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <img
              srcSet={`${item.image}?w=248&fit=crop&auto=format&dpr=2 2x`}
              src={`${item.image}?w=248&fit=crop&auto=format`}
              alt={item.name}
              loading="lazy"
              style={{ width: "100%", height: "auto" }}
            />
            <ImageListItemBar
              title={item.name}
              subtitle={item.userName}
              actionIcon={
                <IconButton
                  sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                ></IconButton>
              }
            />
          </ImageListItem>
        </Link>

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <IconButton
            sx={{ cursor: "pointer" }}
            onClick={() => navigate(`/user/items/create/${item.docId}`)}
          >
            <CreateIcon />
          </IconButton>
          <IconButton
            sx={{ cursor: "pointer" }}
            onClick={() => onRemove(item.docId, item.pictureFileName)}
          >
            <DeleteIcon />
          </IconButton>
          <IconButton
            disabled
            sx={{
              color: "rgba(255, 255, 255, 0.54)",
              display: "flex",
              alignItems: "center",
            }}
          >
            {<ThumbUpIcon sx={{ color: "black" }} />}
            <Typography color="white" sx={{ marginLeft: 0.5 }}>
              {likes}
            </Typography>
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};
