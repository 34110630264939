import {
  Box,
  IconButton,
  ImageListItem,
  ImageListItemBar,
  Link,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { Data } from "../../interfaces/interfaces";
import { useItemLike } from "../../hooks/useItemLike";
import { useUserContext } from "../../contexts/userProviderContext";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { Severity } from "../user/Create";

interface ItemProps {
  item: Data;
  handleClick: (docId: string) => void;
  alertSetup: (alertProps: {
    time: number;
    message: string;
    severity: Severity;
  }) => void;
}

export const Item: FC<ItemProps> = ({ item, handleClick }) => {
  const { likes, likedByUser, toggleLike } = useItemLike(item?.docId);
  const { user } = useUserContext();

  return (
    <>
      <Box
        sx={{
          minWidth: { lg: "250px", md: "200px", sm: "150px" },
          maxWidth: { lg: "450px", md: "250px", sm: "200px" },
          minHeight: { lg: "84px", md: "84px", sm: "84px" },
          maxHeight: { lg: "300px", md: "141px", sm: "113px" },
          margin: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 1,
          boxShadow: 3,
          borderRadius: 0,
          overflow: "hidden",
        }}
      >
        <ImageListItem>
          <Link
            underline="none"
            sx={{ cursor: "pointer" }}
            onClick={() => handleClick(item.docId)}
          >
            <img
              srcSet={`${item.image}?w=248&fit=crop&auto=format&dpr=2 2x`}
              src={`${item.image}?w=248&fit=crop&auto=format`}
              alt={item.name}
              loading="lazy"
              style={{ width: "100%", height: "auto", objectFit: "cover" }}
            />
          </Link>
          <ImageListItemBar
            title={item.name}
            subtitle={item.userName}
            actionIcon={
              user ? (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <IconButton
                    onClick={toggleLike}
                    sx={{
                      color: "rgba(255, 255, 255, 0.54)",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {likedByUser ? <ThumbUpIcon /> : <ThumbUpAltOutlinedIcon />}
                    <Typography color="white" sx={{ marginLeft: 0.5 }}>
                      {likes}
                    </Typography>
                  </IconButton>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    margin: 0.5,
                    color: "white",
                  }}
                >
                  <IconButton
                    disabled
                    sx={{
                      color: "rgba(255, 255, 255, 0.54)",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {<ThumbUpIcon sx={{ color: "black" }} />}
                    <Typography color="white" sx={{ marginLeft: 0.5 }}>
                      {likes}
                    </Typography>
                  </IconButton>
                </Box>
              )
            }
          />
        </ImageListItem>
      </Box>
    </>
  );
};
