import React, { useState } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { useRandomImage } from "../../hooks/useRandomImage";

const Home: React.FC = () => {
  const randomImage1 = useRandomImage();
  const randomImage2 = useRandomImage();
  const randomImage3 = useRandomImage();
  const randomImage4 = useRandomImage();

  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [loading3, setLoading3] = useState(true);

  const handleImageLoad = (setLoading: (loading: boolean) => void) => () => {
    setLoading(false);
  };

  return (
    <>
      <Box
        p={2}
        display="flex"
        border={1}
        borderColor={"#999999"}
        flexDirection="column"
        alignItems="center"
        margin={2}
        justifyContent="center"
        bgcolor="rgba(255, 255, 255, 0.2)"
        width="94%"
      >
        <Typography variant="h6" align="center" gutterBottom>
          Welcome to the Warhammer Times, a place where you can enjoy the best
          Warhammer content on the web. We have the finest articles and the best
          images to make you feel like you are in the Warhammer world.
        </Typography>
      </Box>

      <Box
        bgcolor="rgba(200, 200, 200, 0.5)"
        p={2}
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="94%"
        margin={2}
        sx={{
          backgroundImage: `url(${randomImage4})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            maxWidth: "12800x",
            flexWrap: "wrap",
          }}
          p={2}
          mb={2}
        >
          <Box
            flex="1"
            minWidth="100px"
            bgcolor="rgba(150, 150, 150, 0.5)"
            m={1}
          >
            {loading1 && <CircularProgress />}
            <img
              src={randomImage1}
              alt="1"
              style={{ width: "100%", display: loading1 ? "none" : "block" }}
              onLoad={handleImageLoad(setLoading1)}
            />
          </Box>

          <Box
            flex="2"
            minWidth="200px"
            bgcolor="rgba(150, 150, 150, 0.5)"
            m={1}
          >
            {loading2 && <CircularProgress />}
            <img
              src={randomImage2}
              alt="2"
              style={{ width: "100%", display: loading2 ? "none" : "block" }}
              onLoad={handleImageLoad(setLoading2)}
            />
          </Box>

          <Box
            flex="3"
            minWidth="300px"
            bgcolor="rgba(150, 150, 150, 0.5)"
            m={1}
          >
            {loading3 && <CircularProgress />}
            <img
              src={randomImage3}
              alt="3"
              style={{ width: "100%", display: loading3 ? "none" : "block" }}
              onLoad={handleImageLoad(setLoading3)}
            />
          </Box>

          <Box
            bgcolor="rgba(255, 255, 255, 0.8)"
            p={2}
            width="100%"
            maxWidth="800px"
            mb={2}
          >
            <Typography variant="h5" gutterBottom>
              The Rise of Nagash – A New Era of Darkness
            </Typography>
            <Typography variant="body1" gutterBottom>
              In the once serene lands of Nehekhara, a storm brews as Nagash,
              the Great Necromancer, rises to power. Fueled by an insatiable
              hunger for dominion over life and death, Nagash has returned from
              his crypt to spread terror across the Old World. His legion of the
              undead, the dreaded legions of skeletons and powerful liches,
              march under his command, leaving a trail of death in their wake.
              The living tremble as ancient cities fall to his dark magic, and
              powerful kingdoms prepare for an inevitable clash. In Altdorf, the
              Emperor calls for unity among the Elector Counts, knowing that
              only a combined force can hope to stand against the rising tide of
              necromantic power. As the world edges closer to an age of
              darkness, heroes from all corners of the realm rally, driven by a
              single purpose: to end Nagash's reign before the world is engulfed
              in eternal night.
            </Typography>
          </Box>

          <Box
            bgcolor="rgba(255, 255, 255, 0.8)"
            p={2}
            width="100%"
            maxWidth="800px"
            mb={2}
          >
            <Typography variant="h5" gutterBottom>
              The Fall of Ulthuan – High Elves on the Brink
            </Typography>
            <Typography variant="body1" gutterBottom>
              Ulthuan, the mystical island of the High Elves, faces
              unprecedented peril as the forces of Chaos surge through the Great
              Ocean. The daemonic legions, led by the fearsome prince of
              Tzeentch, Kairos Fateweaver, have launched a full-scale invasion,
              aiming to shatter the heart of elven civilization. Prince Tyrion,
              wielding the legendary Sword of Khaine, leads a valiant defense
              alongside his brother Teclis, whose mastery of the Winds of Magic
              is the elves' last hope. The once unassailable defenses of the
              Glittering Tower and Lothern falter under the relentless assault,
              and refugees pour into the inner sanctuaries of Avelorn. As the
              war rages, a prophecy stirs among the elven seers, hinting at a
              dire choice that could alter the fate of Ulthuan forever. Will the
              proud High Elves hold their ground, or will the tides of Chaos
              wash away their ancient heritage?
            </Typography>
          </Box>

          <Box
            bgcolor="rgba(255, 255, 255, 0.8)"
            p={2}
            width="100%"
            maxWidth="800px"
            mb={2}
          >
            <Typography variant="h5" gutterBottom>
              The Waaagh! of Grimgor Ironhide – Greenskin Fury Unleashed
            </Typography>
            <Typography variant="body1" gutterBottom>
              From the Badlands to the Empire's borders, the ground trembles
              under the march of Grimgor Ironhide's Waaagh! The mightiest
              warlord of the Greenskins has united countless orc tribes and
              goblin hordes, forging an unstoppable wave of destruction. Driven
              by an unquenchable thirst for battle and conquest, Grimgor's
              iron-clad fist crushes everything in his path. His warcry echoes
              through the mountains, rallying orcs, goblins, and trolls to his
              banner in a frenzy of violence. The Dwarfs of Karaz-a-Karak brace
              for impact, their ancient holds fortified against the oncoming
              storm. Human settlements burn as the Empire struggles to muster a
              defense against the green tide. Yet amidst the chaos, whispers of
              dissent grow within Grimgor's ranks, as ambitious warlords eye his
              throne. As the Waaagh! sweeps across the land, the Old World
              braces for the fury of the Greenskin invasion, a clash that
              promises to reshape the very fabric of the realm.
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Home;
