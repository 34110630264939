import { useReducer, useEffect, useState } from "react";
import { collection, doc, updateDoc, setDoc, query, getDoc, DocumentData } from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { firestoreDB } from "../firebase/firebase-config";
import { useUserContext } from "../contexts/userProviderContext";


type UserState = {
  email?: string;
  firstName?: string;
  lastName?: string;
  userName?: string;
  loading?: boolean;
  error?: string;
  userId?: string;
};

type UserAction =
  | { type: "SET_EMAIL"; payload: string }
  | { type: "SET_LOADING"; payload: boolean }
  | { type: "SET_ERROR"; payload: string }
  | { type: "SET_USER_DATA"; payload: Partial<UserState> };

const initialState: UserState = {
  email: "",
  firstName: "",
  lastName: "",
  userName: "",
  loading: true,
  error: "",
  userId: "",
};

const reducer = (state: UserState, action: UserAction): UserState => {
  switch (action.type) {
    case "SET_EMAIL":
      return { ...state, email: action.payload ?? "" };
    case "SET_LOADING":
      return { ...state, loading: action.payload };
    case "SET_ERROR":
      return { ...state, error: action.payload };
    case "SET_USER_DATA":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const useUserProfile = () => {
  const { user } = useUserContext();
  const [state, dispatch] = useReducer(reducer, initialState);
  const collectionRef = collection(firestoreDB, "Users");
  const userQuery = query(collectionRef);
  const [data, loading, error] = useCollectionData(userQuery);
  const [checkDocExiste, setCheckDocExiste] = useState<boolean>(false);
  const [submitMessage, setSubmitMeesage] = useState<string>("");
  const [buttonPressed, setButtonPressed] = useState<boolean>(false);

    useEffect(() => {
      if (buttonPressed) setButtonPressed(false);
    }, [buttonPressed]);

  useEffect(() => {
    
    if (user?.uid && data) {
      const filteredData = data.filter((document) => document.UID === user.uid);
      if (filteredData.length > 0) {
        const { firstName, lastName, userName } = filteredData[0];
        setCheckDocExiste(true);
        dispatch({
          type: "SET_USER_DATA",
          payload: {
            firstName,
            lastName,
            userName,
            email: user.email ?? undefined,
            userId: user.uid,
          },
          
        });
      
      } else {
        dispatch({
          type: "SET_USER_DATA",
          payload: {
            firstName: "",
            lastName: "",
            userName: "",
            email: user.email ?? undefined,
            userId: user.uid,
          },
          
        });
        setCheckDocExiste(false);
        console.log("No matching user found");
      }

      if (loading) {
        dispatch({ type: "SET_LOADING", payload: true });
      } else {
        dispatch({ type: "SET_LOADING", payload: false });
      }

      if (error && error instanceof Error) {
        dispatch({ type: "SET_ERROR", payload: error.message});
      }
    }
  }, [user, data, loading, error]);



  const getUserProfile = async (userId : string) => {
    
        //  if (!userId) {
        //     throw new Error("User not authenticated");
        //   }
     const userRef = doc(firestoreDB, "Users", userId);
     const docSnap = await getDoc(userRef);

     if (docSnap.exists()) {
       return docSnap.data() as DocumentData;
     } else {
       throw new Error("User profile document not found");
     }
   };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    dispatch({
      type: "SET_USER_DATA",
      payload: {
        [name]: value,
      },
    });
  };

  const handleSubmit = async () => {
    if (!user) return;

    const userRef = doc(collectionRef, user.uid);

    try {
      if (!checkDocExiste) {
        await setDoc(userRef, {
          firstName: state.firstName,
          lastName: state.lastName,
          UID: user.uid,
          userName: state.userName,
          email: user.email,
        });
        console.log("User profile created successfully!");
        setSubmitMeesage("User profile created successfully!");
        setButtonPressed(true);
       // alert("User profile created successfully!");
      } else {
        await updateDoc(userRef, {
          firstName: state.firstName,
          lastName: state.lastName,
          userName: state.userName,
          email: state.email,
        });
        console.log("User profile updated successfully!");
        setSubmitMeesage("User profile updated successfully!");
        setButtonPressed(true);
        console.log("buttonPressed", buttonPressed);
        //alert("User profile updated successfully!");

      }
    } catch (err) {
      if (err instanceof Error) {
        console.error("Error creating/updating user profile:", err);
        dispatch({ type: "SET_ERROR", payload: err.message });
      }
    }

  };

  const checkProfile = (): boolean => {
    if (!loading && (!state.firstName || !state.lastName || !state.userName)) {
      console.log("profile not complete");
      return false;
    }
    console.log("profile complete");
    return true;
  };



  return { ...state, handleChange, handleSubmit, checkProfile, submitMessage,setSubmitMeesage, buttonPressed , setButtonPressed, getUserProfile, state, dispatch};
};