import React from "react";
import { Box } from "@mui/material";
import ResponsiveAppBar from "./Navbar";
import {Footer} from "./Footer";
import { Outlet } from "react-router-dom";

const Layout: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh", 
      }}
    >
      <ResponsiveAppBar />
      <Box
        sx={{
          flex: 1, 
          paddingBottom: "100px",
        }}
      >
        <Box sx={{ padding: "20px" }}>

        </Box>
        <Box sx={{ flex: 1 }}>
          <Outlet />
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default Layout;
