import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box, CardActionArea, IconButton } from "@mui/material";
import { useParams } from "react-router-dom";
import { FC, useEffect } from "react";
import { Loading } from "../../components/Loading";
import { useFirebase } from "../../providers/firebaseProvider";
import { AlertComponent } from "../../components/Alerts";
import { useItemLike } from "../../hooks/useItemLike";
import { useUserContext } from "../../contexts/userProviderContext";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { useFilterDataByItemId } from "../../hooks/useFilterDataByItemId";

export const ItemDetail: FC = () => {
  const { itemId } = useParams<{ itemId: string }>();
  const { errorMessage, setErrorMessage, loading } = useFirebase();
  const filteredData = useFilterDataByItemId(itemId ?? "");
  const { selectedItemByItemId } = useFirebase();
  const { likes, likedByUser, toggleLike } = useItemLike(itemId);
  const { user } = useUserContext();

  useEffect(() => {
    try {
      if (!itemId) {
        throw new Error("Item ID is missing.");
      }
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage("An unknown error occurred.");
      }
    }
  }, [itemId, filteredData, selectedItemByItemId, setErrorMessage]);

  if (loading && !errorMessage) {
    return <Loading />;
  }

  if (!filteredData) {
    return (
      <AlertComponent time={0} message="Item not found." severity="error" />
    );
  }

  return (
    <>
      <AlertComponent time={0} message={errorMessage} severity="error" />
      <Box display="flex" justifyContent="center" paddingTop={5} margin={1}>
        <Card>
          <CardActionArea
            onClick={() => {
              window.open(filteredData.image);
            }}
          >
            <CardMedia
              component="img"
              image={filteredData.image}
              alt={filteredData.name}
              sx={{
                height: { xs: 300, sm: 400, md: 600 },
              }}
            />
          </CardActionArea>
          <CardContent
            sx={{
              minWidth: {
                xs: 200,
                sm: 250,
                md: 350,
                lg: 400,
                xl: 450,
              },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: 1,
              }}
            >
              <Typography gutterBottom variant="h5" color="white">
                {filteredData.name}
              </Typography>
              {user ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    onClick={toggleLike}
                    sx={{
                      color: likedByUser
                        ? "rgba(255, 255, 255, 0.54)"
                        : "rgba(255, 255, 255, 0.54)",
                      marginRight: 1, // Add margin for spacing
                    }}
                  >
                    {likedByUser ? (
                      <ThumbUpIcon fontSize="large" />
                    ) : (
                      <ThumbUpAltOutlinedIcon fontSize="large" />
                    )}
                  </IconButton>
                  <Typography variant="h5" color="white">
                    {likes}
                  </Typography>
                </Box>
              ) : (
                <Typography variant="h5" color="white">
                  likes : {likes}
                </Typography>
              )}
            </Box>
            <Typography gutterBottom variant="h5" color="white">
              {"\u00A0"}uploaded by: {filteredData.userName}
              {"\u00A0"}
            </Typography>
            <Typography variant="body2" color="white">
              {filteredData.comment}
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};
