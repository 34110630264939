import  { FC, useEffect, useState } from "react";
import { Alert, AlertTitle } from "@mui/material";
import { AlertComponentProps } from "../interfaces/interfaces";


export const AlertComponent: FC<AlertComponentProps> = ({
  message,
  time,
  severity,
  buttonPressedAlert,
}) => {
  const [showAlert, setShowAlert] = useState<boolean>(false);

  

  useEffect(() => {
    if (buttonPressedAlert && time > 0) {
      setShowAlert(true);

      const hideTimer = window.setTimeout(() => {
        setShowAlert(false);
      }, time);

      return () => clearTimeout(hideTimer);
    } else {
      if (message && time > 0) {
        setShowAlert(true);

        const hideTimer = window.setTimeout(() => {
          setShowAlert(false);
        }, time);

        return () => clearTimeout(hideTimer);
      } else if (message && time === 0) {
        setShowAlert(true);
      }
    }
  }, [message, time, buttonPressedAlert]);

  return (
    <>
      {showAlert && message && (
        <Alert
          sx={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
          severity={severity}
        >
          <AlertTitle sx={{ width: "100%", textAlign: "center" }}>
            {message}
          </AlertTitle>
        </Alert>
      )}
    </>
  );
};
