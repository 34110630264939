import { useCallback, useEffect, useState } from "react";
import { useFirebaseContext } from "../contexts/useFirebaseContect";
import { useFirebase } from "../providers/firebaseProvider";
import { Data } from "../interfaces/interfaces";

export const useFilterDataByItemId = (itemId: string): Data | null => {
     const { data } = useFirebaseContext();
  const { setSelectedItemByItemId, setErrorMessage } = useFirebase();

  const filterDataByItemIdCallBack = useCallback((): Data | null => {
    try {
      console.log("Filtering data by item ID...");
      const filteredItem = data
        ? data.find((item) => item.docId === itemId) ?? null
        : null;
      setSelectedItemByItemId(filteredItem !== null ? filteredItem : null);
      console.log("Data filtered successfully:", filteredItem);
      return filteredItem;
    } catch (error) {
      console.error("Error filtering data:", error);
      setErrorMessage("error : " + error);
      return null;
    } finally {
      console.log("Filtering completed.");
    }
  }, [data, setSelectedItemByItemId, setErrorMessage, itemId]);

  const [filteredData, setFilteredData] = useState<Data | null>(null);

  useEffect(() => {
    const result = filterDataByItemIdCallBack();
    setFilteredData(result);

    return () => {
      setSelectedItemByItemId(null);
      console.log("Cleanup completed.");
    };
  }, [data, filterDataByItemIdCallBack, setSelectedItemByItemId, itemId]);

  return filteredData;
};
