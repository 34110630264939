import  { FC, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Loading } from "../../components/Loading";
import { useUserProfileContext } from "../../contexts/userProfileContext";
import { AlertComponent } from "../../components/Alerts";
import { useUserContext } from "../../contexts/userProviderContext";

export const Profile: FC = () => {
  const {
    handleChange,
    handleSubmit,
    email,
    firstName,
    lastName,
    userName,
    loading,
    error,
    checkProfile,
    submitMessage,
    setSubmitMeesage,
    buttonPressed,
  } = useUserProfileContext();
  const { user } = useUserContext();


  //const error = "test error message"
  
    useEffect(() => {
      setSubmitMeesage("");
    }, [setSubmitMeesage]);

  if (loading) {
    return <Loading />;
  }
  
return (
  <>
    {error && (
      <AlertComponent
        message={"error : " + error}
        time={2000}
        severity="error"
      />
    )}
    {checkProfile() ? (
      <Typography></Typography>
    ) : (
      <AlertComponent
        message={"please update your profille"}
        time={0}
        severity="warning"
      />
    )}
    ;
    <AlertComponent
      message={submitMessage}
      time={2000}
      severity="success"
      buttonPressedAlert={buttonPressed}
    />
    <Box
      display="flex"
      flexDirection="column"
      alignContent="center"
      justifyContent="center"
      alignItems="center"
    >
      <Typography variant="h4">Profile Page</Typography>
    </Box>
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      {user && !user.emailVerified && (
        <Typography variant="h5" color="error">
          Please verify your email to access other user parts of the app.
        </Typography>
      )}
    </Box>
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box marginTop={2}>
          <FormControl>
            <Box marginTop={1}>
              <FormLabel component="legend">User Name</FormLabel>
              <TextField
                value={userName}
                onChange={handleChange}
                name="userName"
                fullWidth
              />
            </Box>
            <Box marginTop={1}>
              <FormLabel>First Name</FormLabel>
              <TextField
                value={firstName}
                onChange={handleChange}
                name="firstName"
                fullWidth
              />
            </Box>
            <Box marginTop={1}>
              <FormLabel>Last Name</FormLabel>
              <TextField
                value={lastName}
                onChange={handleChange}
                name="lastName"
                fullWidth
              />
            </Box>
            <Box marginTop={1}>
              <FormLabel>Email</FormLabel>
              <TextField value={email} disabled name="email" fullWidth />
            </Box>
            <Box marginTop={2}>
              <Button
                type="submit"
                onClick={() => {
                  handleSubmit();
                }}
                fullWidth
              >
                Update Profile
              </Button>
            </Box>
          </FormControl>
        </Box>
      </Box>
      {error && <Typography color="error">Error: {error}</Typography>}
    </Box>
  </>
);

};
