import { createTheme, PaletteOptions } from "@mui/material/styles";
import backgroundImage from "../images/pastel-yellow-vignette-concrete-textured-background.jpg";

const palette: PaletteOptions = {
  primary: {
    main: "#000000",
  },
  secondary: {
    main: "#808080",
  },
  background: {
    default: "#e5decf",
  },
};

const hasMainProperty = (color: any): color is { main: string } => {
  return color && typeof color === "object" && "main" in color;
};

const typography = {
  fontFamily: "Roboto Slab",
  h1: {
    fontSize: 48,
    fontWeight: 700,
    lineHeight: 1.2,
  },
  h2: {
    fontSize: 36,
    fontWeight: 600,
    lineHeight: 1.3,
  },
  h3: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: 1.4,
  },
  body1: {
    fontSize: 16,
    lineHeight: 1.6,
  },
  body2: {
    fontSize: 14,
    lineHeight: 1.5,
  },
};

const components = {
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        backgroundImage: `url(${backgroundImage})`,
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundColor: hasMainProperty(palette.primary)
          ? palette.primary.main
          : "#FFFFFF",
        borderRadius: 8,
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        backgroundColor: hasMainProperty(palette.primary)
          ? palette.primary.main
          : "#FFFFFF",
        color: "#FFFFFF",
        padding: 10,
        borderRadius: 8,
        "&:hover": {
          backgroundColor: hasMainProperty(palette.secondary)
            ? palette.secondary.main
            : "#FFFFFF",
        },
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      h1: {
        color: hasMainProperty(palette.primary)
          ? palette.primary.main
          : "#FFFFFF",
        fontSize: 48,
        fontWeight: 700,
        lineHeight: 1.2,
      },
    },
  },
};

const theme = createTheme({
  palette,
  typography,
  components,
});

export default theme;
