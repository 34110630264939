import { useCallback, useEffect, useState } from "react";
//import { useFirestoreData } from "../providers/firebaseProvider";
import { useFirebaseContext } from "../contexts/useFirebaseContect";
import { Data } from "../interfaces/interfaces";
import { useFirebase } from "../providers/firebaseProvider";

export const useFilterDataByUserId = (userId: string): Data[] | null => {
     const { data } = useFirebaseContext();
  //const { data } = useFirestoreData();
  const { setSelectedItemsByUserId, setErrorMessage } = useFirebase();

  const filterDataByUserIdCallBack = useCallback((): Data[] | null => {
    try {
      console.log("Filtering data...");
      const filteredItems = data
        ? data.filter((item) => item.userId === userId)
        : [];
      setSelectedItemsByUserId(filteredItems);
      console.log("Data filtered successfully:", filteredItems);
      return filteredItems;
    } catch (error) {
      console.error("Error filtering data:", error);
      setErrorMessage("error : " + error);
      return null;
    } finally {
      console.log("Filtering operation completed.");
    }
  }, [data, setSelectedItemsByUserId, setErrorMessage, userId]);

  const [filteredData, setFilteredData] = useState<Data[] | null>(null);

  useEffect(() => {
    const result = filterDataByUserIdCallBack();
    setFilteredData(result);

    return () => {
      setSelectedItemsByUserId(null);
      console.log("Cleanup completed.");
    };
  }, [data, filterDataByUserIdCallBack, setSelectedItemsByUserId, userId]);

  return filteredData;
};
