import { Box, Button, TextField, Typography } from "@mui/material";
import { FC, useState } from "react";
import { useUserContext } from "../../contexts/userProviderContext";
import { Link, useNavigate } from "react-router-dom";
import { Loading } from "../../components/Loading";
import { useUserProfileContext } from "../../contexts/userProfileContext";
import { AlertComponent } from "../../components/Alerts";
import { DocumentData } from "firebase/firestore";

export const LoginPage: FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isSignupMode, setIsSignupMode] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  // const[IsLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  const { user, signup, signin, isLoading, buttonPressed } = useUserContext();
  const navigate = useNavigate();
  const { getUserProfile } = useUserProfileContext();
  const [loading, setLoading] = useState<boolean>(false);

  const onSignup = async () => {
    setError(false);

    if (password !== confirmPassword) {
      setError(true);
      setErrorMessage("Passwords do not match");
      return;
    }

    const result = await signup(email, password);
    setLoading(true);

    if (result.error) {
      console.error(result.error);
      setError(true);
      setLoading(false);
      setErrorMessage(result.error);
    }

    if (result.user) {
      setError(false);
      setLoading(false);
      navigate("/user/profile");
      setLoading(false);
    }
  };

  const onSignin = async () => {
    setLoading(true);
    setError(false);
    const result = await signin(email, password);

    if (result.error) {
      console.error(result.error);
      setError(true);
      setLoading(false);
      setErrorMessage(result.error);
      return; 
    }

    if (result.user) {
      setError(false);
      const userProfile = await getUserProfile(result.user.uid);
      if (isFirebaseProfileComplete(userProfile)) {
        navigate("/user/items");
      } else {
        navigate("/user/profile");
      }
    }
  };

  const isFirebaseProfileComplete = (userProfile: DocumentData) => {
    if (
      userProfile.firstName === "" ||
      userProfile.lastName === "" ||
      userProfile.userName === ""
    )
      return false;
    else {
      return true;
    }
  };
  

  if (isLoading || loading) {
    console.log("loading");
    return <Loading />;
  }

  return (
    <>
      {error && (
        <AlertComponent
          message={errorMessage}
          time={5000}
          severity="error"
          buttonPressedAlert={buttonPressed}
        />
      )}

      <Box display="flex" flexDirection="column" alignItems="center" width={1}>
        <Typography variant="h4" textAlign="center">
          {isSignupMode ? "Signup" : "Login"}
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width={1}
        >
          <Box
            display="flex"
            flexDirection="column"
            maxWidth="500px"
            width={1}
            padding={2}
            gap={2}
          >
            <TextField
              variant="outlined"
              label="Email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              InputProps={{ style: { background: "transparent" } }}
            />
            <TextField
              variant="outlined"
              label="Password"
              type="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              InputProps={{ style: { background: "transparent" } }}
            />
            {!isSignupMode && (
              <Box display="flex" justifyContent="flex-end">
                <Link to="reset">Forgot password?</Link>
              </Box>
            )}
            {isSignupMode && (
              <TextField
                variant="outlined"
                label="Confirm Password"
                type="password"
                value={confirmPassword}
                onChange={(event) => setConfirmPassword(event.target.value)}
                InputProps={{ style: { background: "transparent" } }}
              />
            )}
            <Box display="flex" gap={2}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={isSignupMode ? onSignup : onSignin}
              >
                {isSignupMode ? "Signup" : "Signin"}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={() => setIsSignupMode(!isSignupMode)}
              >
                {isSignupMode ? "Switch to Signin" : "Switch to Signup"}
              </Button>
            </Box>
            {!isSignupMode && (
              <>
                <Typography
                  variant="body2"
                  textAlign="center"
                  color="textSecondary"
                >
                  Don't have an account? Press "Switch to Signup" to create one.
                </Typography>
              </>
            )}
            {error && (
              <Typography variant="body2" textAlign="center" color="error">
                {errorMessage}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};
